import gql from 'graphql-tag'

export const MUTATION_UPDATE_APP = gql`
  mutation($account_id: ID!, $app_config: AppConfigInputParams) {
    updateApp(account_id: $account_id, app_config: $app_config)
  }
`
export const MUTATION_UPLOAD_IMAGE = gql`
  mutation($account_id: ID!, $tag: String!, $file: Upload!) {
    fileUpload(account_id: $account_id, tag: $tag, file: $file)
  }
`
export const MUTATION_UPLOAD_IMAGES = gql`
  mutation($account_id: ID!, $files: Files) {
    filesUpload(account_id: $account_id, files: $files)
  }
`
export const MUTATION_REMOVE_APP = gql`
  mutation($id: ID!, $appName: String!) {
    removeApp(id: $id, appName: $appName)
  }
`
export const MUTATION_TRIGGER_FIREBASE = gql`
  mutation($account_id: ID!) {
    triggerFirebase(account_id: $account_id)
  }
`
export const MUTATION_UPDATE_APP_FLAGS = gql`
  mutation($account_id: ID!, $flags: JSONObject) {
    updateAccountAppFlags(account_id: $account_id, flags: $flags)
  }
`
export const MUTATION_UPDATE_APP_FEATURE_FLAGS = gql`
  mutation($account_id: ID!, $flags: JSONObject) {
    updateAccountAppFeatureFlags(account_id: $account_id, flags: $flags)
  }
`
export const QUERY_APP_CONFIG = gql`
  query($account_id: ID!) {
    appConfig(account_id: $account_id) {
      channels {
        app {
          active
          points
        }
      }
    }
  }
`
export const QUERY_CONFIGURATION = gql`
  query($account_id: ID!) {
    configuration(account_id: $account_id) {
      store_links {
        app_store
        play_store
      }
      points {
        active
        rate
        start_date
        end_date
        last_points_computation_at
        duration {
          scale
          amount
        }
      }
      green_coupon {
        active
        integration {
          url
          api_key
        }
      }
      app_update {
        android {
          minimum_version
          recommended_version
        }
        ios {
          minimum_version
          recommended_version
        }
        manually_force_update
      }
      app_update_type
      login_google_config {
        enable_login_with_google
      }
      nearest_store_config {
        enable_nearest_store_sheet
      }
      retailer_flag_config {
        start_with_selector
      }
    }
  }
`

export const QUERY_HOME_BUTTONS = gql`
  query($account_id: ID!) {
    appHomeButtons(account_id: $account_id) {
      id
      link
      type
    }
  }
`

export const QUERY_OFFERS_ORDER = gql`
  query GetOffersOrder($accountId: String!) {
    screenComponents(accountId: $accountId) {
      order
      type
    }
  }
`

export const MUTATION_UPDATE_SCREEN_COMPONENTS = gql`
  mutation UpdateScreenComponents($input: UpdateScreenComponentsInput!) {
    updateScreenComponents(input: $input) {
      message
      status
    }
  }
`

export const MUTATION_UPDATE_APP_POINTS = gql`
  mutation(
    $account_id: ID!
    $active: Boolean!
    $home_button: Boolean!
    $rate: Float!
    $scale: DurationScale!
    $amount: Int!
    $start_date: String!
    $end_date: String!
  ) {
    updateAppPointsConfiguration(
      account_id: $account_id
      active: $active
      home_button: $home_button
      rate: $rate
      scale: $scale
      amount: $amount
      start_date: $start_date
      end_date: $end_date
    )
  }
`
export const MUTATION_UPDATE_STORE_LINKS = gql`
  mutation($account_id: ID!, $app_store: String, $play_store: String) {
    updateAppStoreLinks(account_id: $account_id, app_store: $app_store, play_store: $play_store)
  }
`
export const MUTATION_UPDATE_APP_VERSION = gql`
  mutation($accountId: ID!, $input: UpdateAppUpgradeConfigInput) {
    updateAppUpgradeConfig(accountId: $accountId, input: $input)
  }
`
export const MUTATION_UPDATE_APP_MODEL = gql`
  mutation($accountId: ID!, $type: AppUpdateType!) {
    updateAppUpgradeType(accountId: $accountId, type: $type)
  }
`

// new endpoints
export const MUTATION_CREATE_APP = gql`
  mutation($account_id: String!, $appleKeyType: String!, $input: CreateAppInput!) {
    createApp(account_id: $account_id, appleKeyType: $appleKeyType, input: $input) {
      account_id
      app_name
      keystore {
        key_alias
        key_password
        store_file
        store_password
      }
    }
  }
`
export const QUERY_GET_APP_BUILD_LOGS = gql`
  query($fast_lane_action: FastlaneCommands, $page: Int, $pageSize: Int, $search: String, $sort: [String!], $sortDirection: [SortDirection!]) {
    appBuildLogs(
      filters: { fast_lane_action: $fast_lane_action }
      pagination: { page: $page, pageSize: $pageSize, search: $search, sort: $sort, sortDirection: $sortDirection }
    ) {
      data {
        _id
        app_build
        branch
        account_id
        build_output {
          build_number
          build_slug
          build_url
          message
          service
          slug
          status
          triggered_workflow
        }
        changelog
        created_at
        fast_lane_action
        test
        user {
          _id
          name
          login
        }
        workflow_id
        details {
          triggered_at
          started_on_worker_at
          environment_prepare_finished_at
          finished_at
          slug
          status
          status_text
          abort_reason
          is_on_hold
          is_processed
          is_status_sent
          branch
          build_number
          commit_hash
          commit_message
          tag
          triggered_workflow
          triggered_by
          machine_type_id
          stack_identifier
          original_build_params {
            workflow_id
            branch
            environments {
              value
              is_expand
              key
            }
          }
          pipeline_workflow_id
          pull_request_id
          pull_request_target_branch
          pull_request_view_url
          commit_view_url
          credit_cost
        }
      }
      count
    }
  }
`
export const QUERY_SINGLE_APP = gql`
  query($account_id: String!) {
    singleApp(account_id: $account_id) {
      _id
      db_name
      name
      modules {
        enable_expanded_banner
      }
      channels {
        consumer_app {
          enable_bottom_menu_icon
          enable_expanded_logo_home
          enable_colorful_register_screen
          enable_header_gradient
          assets {
            colors {
              primary
              primary_text
              primary_light
              primary_text_light
              primary_dark
              primary_text_dark
              secondary
              secondary_text
              secondary_light
              secondary_text_light
              secondary_dark
              secondary_text_dark
              composition {
                components {
                  name
                  value
                }
                general
              }
            }
          }
          urls
        }
        ecommerce_v2 {
          active
          integration {
            url
          }
          web {
            active
          }
          app {
            active
          }
        }
        club {
          active
          web {
            active
          }
          app {
            active
          }
        }
        app {
          active
          firebase {
            certificate_version
          }
          points {
            active
          }
          push {
            credentials {
              firebase_version
            }
            provider
          }
          build {
            account_id
            enable_full_screen_splash
            android {
              type_release
            }
            app_name
            app_type
            apple {
              release_mode
              type_submit_review_ios
            }
            apple_account_data {
              fastlane_apple_application_specific_password
              itc_team_id
              match_keychain_password
              team_id
              team_name
            }
            assets {
              banner_store_android
              master_icon_navbar
              icon_android
              icon_ios
              icon_navbar
              icon_store_android
              splash
              rounded_logo_image
              full_screen_splash
            }
            codepush {
              android
              api_token
              deployment_key_ios
              deployment_key_android
              force_install
              ios
            }
            colors {
              main
              main_text
              nav
              nav_text
              splash
              primary
              primary_text
              primary_light
              primary_text_light
              primary_dark
              primary_text_dark
              secondary
              secondary_text
              secondary_light
              secondary_text_light
              secondary_dark
              secondary_text_dark
              auth_screen_config {
                text_color
                background_image
              }
              composition {
                components {
                  name
                  value
                }
                general
              }
            }
            debug_api
            facebook {
              app_id
              bundle
              client_token
              display_name
            }
            google {
              cloud_api_key
            }
            keystore {
              key_alias
              key_password
              store_file
              store_password
            }
            metadata {
              android {
                aab_link
                full_description
                google_services_json {
                  client {
                    api_key {
                      current_key
                    }
                    client_info {
                      android_client_info {
                        package_name
                      }
                      mobilesdk_app_id
                    }
                    oauth_client {
                      android_info {
                        certificate_hash
                        package_name
                      }
                      client_id
                      client_type
                    }
                    services {
                      appinvite_service {
                        other_platform_oauth_client {
                          client_id
                          client_type
                          ios_info {
                            bundle_id
                          }
                        }
                      }
                    }
                  }
                  configuration_version
                  project_info {
                    project_id
                    project_number
                    storage_bucket
                  }
                }
                key_json {
                  auth_provider_x509_cert_url
                  auth_uri
                  client_email
                  client_id
                  client_x509_cert_url
                  private_key
                  private_key_id
                  project_id
                  token_uri
                  type
                }
                keystore_link
                short_description
                title
                token_sha1
                upload_sha1
              }
              firebase_app
              ios {
                apple_id
                description
                google_service_info
                keywords
                marketing_url
                name
                privacy_url
                promotional_text
                release_notes
                short_description
                support_url
                key_json {
                  duration
                  in_house
                  issuer_id
                  key
                  key_id
                }
              }
            }
            store {
              android_bundle_id
              android_build_code
              android_version_code
              ios_bundle_id
              ios_build_code
              ios_version_code
              name
            }
            web_client_id
          }
        }
      }
      defaultAccountApp {
        channels {
          app {
            build {
              colors {
                composition {
                  components {
                    name
                    value
                  }
                  general
                }
              }
            }
          }
          consumer_app {
            assets {
              colors {
                composition {
                  components {
                    name
                    value
                  }
                  general
                }
              }
            }
          }
        }
      }
    }
  }
`
export const MUTATION_UPDATE_APP_PROPS = gql`
  mutation($account_id: String!, $update: AccountAppUpdate!) {
    updateApp(account_id: $account_id, update: $update) {
      channels {
        app {
          build {
            app_name
          }
        }
      }
    }
  }
`
export const MUTATION_UPDATE_APP_TYPE = gql`
  mutation($input: UpdateAppTypeInput!) {
    updateAppType(input: $input) {
      message
      status
    }
  }
`
export const MUTATION_UPDATE_GREEN_COUPON = gql`
  mutation($accountId: ID!, $input: UpdateGreenCouponInput!) {
    updateGreenCouponConfig(accountId: $accountId, input: $input)
  }
`
export const MUTATION_UPDATE_LOGIN_GOOGLE = gql`
  mutation($accountId: ID!, $input: UpdateLoginGoogleConfigInput!) {
    updateLoginGoogleConfig(accountId: $accountId, input: $input)
  }
`
export const MUTATION_UPDATE_NEAREST_STORE = gql`
  mutation($accountId: ID!, $input: UpdateNearestStoreConfigInput!) {
    updateNearestStoreConfig(accountId: $accountId, input: $input)
  }
`
export const MUTATION_UPDATE_KEYSTORE = gql`
  mutation($uploadKeyStoreParams: KeyStoreUpload!) {
    uploadKeyStore(uploadKeyStoreParams: $uploadKeyStoreParams)
  }
`
export const MUTATION_UPLOAD_ASSETS = gql`
  mutation($uploadInput: UploadAssetsInput!) {
    uploadAssets(uploadInput: $uploadInput) {
      message
      status
    }
  }
`
export const MUTATION_CREATE_FIREBASE = gql`
  mutation($account_id: String!) {
    triggerFirebase(account_id: $account_id) {
      _id
      db_name
      name
    }
  }
`

export const GET_MERCASHOP_THEMES = gql`
  query MercashopThemes {
    mercashopThemes {
      _id
      active
      colors {
        primary
        primaryDark
        primaryLight
        primaryText
        primaryTextDark
        primaryTextLight
        secondary
        secondaryDark
        secondaryLight
        secondaryText
        secondaryTextDark
        secondaryTextLight
      }
      customSchema
      default
      name
    }
  }
`
export const GET_MERCASHOP_THEMES_BY_ID = gql`
  query MercashopTheme($mercashopThemeId: String!) {
    mercashopTheme(mercashopThemeId: $mercashopThemeId) {
      _id
      active
      colors {
        primary
        primaryDark
        primaryLight
        primaryText
        primaryTextDark
        primaryTextLight
        secondary
        secondaryDark
        secondaryLight
        secondaryText
        secondaryTextDark
        secondaryTextLight
      }
    }
  }
`

export const MUTATION_CREATE_MERCASHOP_THEME_COLORS = gql`
  mutation RegisterMercashopTheme($mercashopTheme: MercashopThemeInput!) {
    registerMercashopTheme(mercashopTheme: $mercashopTheme) {
      message
      success
    }
  }
`
export const MUTATION_UPDATE_MERCASHOP_THEME_COLORS = gql`
  mutation UpdateMercashopTheme($mercashopThemeId: String!, $update: MercashopThemeUpdate!) {
    updateMercashopTheme(mercashopThemeId: $mercashopThemeId, update: $update) {
      message
      success
    }
  }
`

export const MUTATION_REMOVE_MERCASHOP_THEME_COLORS = gql`
  mutation DeleteMercashopTheme($mercashopThemeId: String!) {
    deleteMercashopTheme(mercashopThemeId: $mercashopThemeId) {
      message
      success
    }
  }
`
